// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"DGj1aNJql-fKmYsz00F6G"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs';

Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    beforeSend(event) {
        // Lấy thông tin User-Agent từ headers
        const userAgent = event.request?.headers?.['x-user-agent'] || event.request?.headers?.['user-agent'] || '';
        if (/googlebot|bingbot|crawler|slurp|duckduckbot|baiduspider|yandexbot|sogou/i.test(userAgent)) {
            // Bỏ qua sự kiện nếu là bot
            return null;
        }
        return event; // Giữ lại sự kiện nếu không phải bot
    },
    // Add optional integrations for additional features
    integrations: [
        Sentry.replayIntegration({
            maskAllText: false,
            maskAllInputs: false,
            networkRequestHeaders: ['Cache-Control'],
            networkResponseHeaders: ['Referrer-Policy'],
            networkDetailAllowUrls: [window.location.origin]
        }),
        Sentry.captureConsoleIntegration({ levels: ['error'] }),
        Sentry.breadcrumbsIntegration({
            console: true,
            dom: true,
            fetch: true,
            history: true,
            xhr: true
        }),
        Sentry.browserApiErrorsIntegration({
            setTimeout: true,
            setInterval: true,
            requestAnimationFrame: true,
            XMLHttpRequest: true,
            eventTarget: true
        })
    ],

    // Define how likely traces are sampled. Adjust this value in production, or use tracesSampler for greater control.
    tracesSampleRate: 1,

    // Define how likely Replay events are sampled.
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // Define how likely Replay events are sampled when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    // Setting this option to true will print useful information to the console while you're setting up Sentry.
    debug: false
});
